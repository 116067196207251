// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-no-blog-list-js": () => import("/opt/build/repo/APPIFY/src/templates/no-blog-list.js" /* webpackChunkName: "component---src-templates-no-blog-list-js" */),
  "component---src-templates-job-list-templates-js": () => import("/opt/build/repo/APPIFY/src/templates/job-list-templates.js" /* webpackChunkName: "component---src-templates-job-list-templates-js" */),
  "component---src-templates-job-single-js": () => import("/opt/build/repo/APPIFY/src/templates/jobSingle.js" /* webpackChunkName: "component---src-templates-job-single-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/APPIFY/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/APPIFY/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/APPIFY/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/APPIFY/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

